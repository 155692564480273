import React from 'react';
import { K3Extensions, K3Main, ProvidedExtensionsProvider } from '@k3/core';

const extensions: K3Extensions = {};

export const App = () => {
  return (
    <ProvidedExtensionsProvider extensions={extensions}>
      <K3Main />
    </ProvidedExtensionsProvider>
  );
};
